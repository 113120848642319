.er_team {
  background-color: $white-grey;
  padding: 100px 0;

  @include respond-to(tablet) {
    padding: 80px 0;
  }

  @include respond-to(phone) {
    padding: 60px 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title {
      max-width: 760px;
      width: 100%;
      margin-bottom: 32px;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: $black;

      @include respond-to(phone) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 26px;
      }
    }

    &__link {
      @include main-button;
      width: 100%;
      max-width: 312px;
    }
  }
}

.er_corporate {
  padding-top: 120px;
  padding-bottom: 120px;

  @include respond-to(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include respond-to(phone) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__inner {
    &__banner {
      background-color: $white-grey;
      padding: 72px 100px 80px;
      border-radius: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to(tablet) {
        flex-direction: column;
        padding: 62px 80px 60px;
      }

      @include respond-to(phone) {
        padding: 42px 60px 40px;
      }

      @include respond-to(mini) {
        padding: 32px 40px 30px;
      }

      &__left {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-to(tablet) {
          align-items: center;
          margin-right: 0;
          margin-top: 40px;
        }

        &__title {
          font-weight: 800;
          font-size: 32px;
          line-height: 40px;
          color: $black;
          margin-bottom: 32px;

          @include respond-to(tablet) {
            text-align: center;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 20px;
          }

          @include respond-to(phone) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 16px;
          }
        }

        &__text {
          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $grey;

            @include respond-to(tablet) {
              text-align: center;
              font-size: 16px;
              line-height: 22px;
            }

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 20px;
            }

            &:not(:last-child) {
              margin-bottom: 16px;

              @include respond-to(tablet) {
                margin-bottom: 12px;
              }

              @include respond-to(phone) {
                margin-bottom: 10px;
              }
            }
          }
        }

        &__link {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: $pink;
          display: flex;
          align-items: center;
          transition: color 0.25s linear;

          @include respond-to(phone) {
            font-size: 16px;
            line-height: 22px;
          }

          svg {
            margin-left: 10px;

            path {
              fill: $pink;
              transition: fill 0.25s linear;
            }
          }

          &:hover {
            color: $pink-hover;

            svg {
              path {
                fill: $pink-hover;
              }
            }
          }
        }
      }

      &__right {
        flex-shrink: 0;

        @include respond-to(tablet) {
          order: -1;
        }
      }
    }

    &__title {
      margin: 160px auto 0;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: $black;
      max-width: 760px;
      width: 100%;

      @include respond-to(tablet) {
        font-size: 24px;
        line-height: 30px;
        margin: 120px auto 0;
      }

      @include respond-to(phone) {
        font-size: 20px;
        line-height: 25px;
        margin: 80px auto 0;
      }
    }
  }
}

/* Timings */

$length-of-bg-change-animation: 0.3s;
$length-of-text-change-animation: 0.4s;
$length-of-slide-with-change-animation: 11.6s;

/* Animations */

@mixin slider-animation() {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity $length-of-bg-change-animation linear, z-index $length-of-bg-change-animation linear;
}

@mixin progressbar() {
  content: '';
  z-index: 1000;
  position: absolute;
  border: 2px solid $white-100;
  animation-duration: $length-of-slide-with-change-animation;
  animation-delay: $length-of-bg-change-animation;
  animation-name: progressbar;
  animation-timing-function: linear;
  visibility: hidden;
  align-self: flex-start;
  left: 0;
  border-radius: 2px;
}

@keyframes progressbar {
  from {
    width: 0;
    visibility: visible;
  }
  to {
    width: 100%;
  }
}

/* Slide Main */

.er_main {
  position: relative;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $black;
  transition: height 0.05s linear;

  &__bck {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    &__item {
      @include slider-animation;
      img,
      video,
      picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        &::-webkit-media-controls-panel {
          display: none !important;
          -webkit-appearance: none;
        }

        &::-webkit-media-controls-play-button {
          display: none !important;
          -webkit-appearance: none;
        }

        &::-webkit-media-controls-start-playback-button {
          display: none !important;
          -webkit-appearance: none;
        }

        &::-webkit-media-controls-overlay-play-button {
          display: none;
        }
      }

      &_active {
        opacity: 1;
        z-index: 1;
      }

      &_leaving {
        z-index: -1;
        opacity: 0;
      }
    }
  }

  &__info {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    min-height: calc(83% - 85px);
    margin-top: 85px;
    color: $white-100;

    @include respond-to(tablet) {
      min-height: calc(88% - 72px);
    }

    @include respond-to(phone) {
      min-height: calc(83% - 72px);
    }

    &::after {
      content: '';
      width: 0;
      min-height: inherit;
      font-size: 0;
    }

    &__title {
      position: absolute;
      width: calc(100% - 180px);
      height: 100%;
      top: 0;
      left: 0;
      margin: 0 90px;

      @include respond-to(desktop-small) {
        width: calc(100% - 100px);
        margin: 0 50px;
      }

      @include respond-to(tablet-large) {
        width: calc(100% - 64px);
        margin: 0 32px;
      }

      &__item {
        opacity: 0;
        position: absolute;
        color: $white-100;
        transition: all $length-of-text-change-animation linear;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;
        height: 100%;

        &__bubbles {
          position: relative;
          width: 100%;
          height: 100%;
          margin-bottom: 2vh;

          @include respond-to(tablet) {
            display: none;
          }

          &__item {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            img {
              object-fit: contain;
            }

            &:nth-child(1) {
              width: 24.44vh;
              height: 7.69vh;
              transform: translate(-43vh, -5vh);
            }
            &:nth-child(2) {
              width: 22.22vh;
              height: 6.49vh;
              transform: translate(6vh, 12vh);
            }
            &:nth-child(3) {
              width: 27.08vh;
              height: 7.69vh;
              transform: translate(24vh, -2vh);
            }
          }
        }

        &__picture {
          position: relative;
          width: 100%;
          height: 100%;
          margin-bottom: 2vh;

          @include respond-to(tablet) {
            display: none;
          }

          img {
            position: absolute;
            height: 100%;
            left: 0;
            right: 0;
            margin: auto;
          }
        }

        &__bottom {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-shrink: 0;

          @include respond-to(tablet) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        h1,
        h3 {
          font-weight: 800;
          font-size: 5vh;
          line-height: 5.55vh;
          width: 100%;
          margin-right: 3.7vh;
          max-width: 105vh;

          @include respond-to(tablet) {
            margin-right: 0;
            margin-bottom: 2.7vh;
            font-size: 4vh;
            line-height: 4.44vh;
          }

          @include respond-to(phone) {
            font-size: 4.5vh;
            line-height: 5vh;
            word-wrap: break-word;
          }
        }

        &__desc {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          max-width: 45.37vh;
          width: 100%;

          @include respond-to(tablet) {
            max-width: initial;
          }

          span {
            font-weight: 500;
            font-size: 1.85vh;
            line-height: 2.59vh;
            margin-bottom: 2.59vh;
          }

          a {
            @include main-button;
            padding: 1.48vh 3.33vh;
            border-radius: 4.63vh;
            font-size: 1.48vh;
            line-height: 1.85vh;
          }
        }
        &_active {
          opacity: 1;
          z-index: 1;
        }

        &_leaving {
          opacity: 0;
        }
      }
    }
  }

  &__switcher {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3.7vh;
    z-index: 3;

    @include respond-to(tablet) {
      bottom: calc(var(--vh, 1vh) * 2);
    }

    @include respond-to(phone) {
      bottom: calc(var(--vh, 1vh) * 4);
    }

    &:hover {
      cursor: pointer;
    }

    &__item {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 3.7vh 0;

      @include respond-to(tablet) {
        padding: 2.7vh 0;
      }

      &:not(:last-child) {
        margin-right: 0.93vh;

        @include respond-to(phone) {
          margin-right: 10px;
        }
      }

      span {
        z-index: 100;
        margin-top: 2.4vh;
        color: $white-60;
        font-weight: 600;
        font-size: 1.67vh;
        line-height: 2.04vh;

        @include respond-to(tablet) {
          display: none;
        }
      }

      &::after {
        content: '';
        align-self: flex-start;
        left: 0;
        border-radius: 2px;
        width: 100%;
        top: 1.3vh;
        z-index: 1000;
        position: absolute;
        border: 2px solid $white-30;

        @include respond-to(tablet) {
          top: 50%;
        }
      }

      &_active {
        &::before {
          @include progressbar();
          top: 1.3vh;
          box-sizing: border-box;

          @include respond-to(tablet) {
            top: 50%;
          }
        }

        span {
          color: $white-100;
        }
      }

      &_reset {
        &::before {
          animation-delay: 0s;
        }
      }
    }
  }
}
